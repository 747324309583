<template>
	<label
		class="tn-toggle"
		:class="[size && `size-${size}`, checkedClass, { dark }, { disabled }, { loading }]"
	>
		<input
			:disabled="disabled || loading"
			type="checkbox"
			:checked="isChecked"
			:name="name"
			:value="modelValue"
			@change="handleInput"
		/>
		<div
			class="track"
			:class="[{ disabled: disabled, loading }, color]"
		>
			<div
				class="handle"
				:class="{ loading: loading }"
				@update:modelValue="$emit('change', selected)"
			>
				<TnProgressCircular
					v-if="loading"
					rounded
					indeterminate
					block
					class="loader"
					:stroke="5"
					:color="loadingColor"
				/>
			</div>
		</div>
		<slot
			name="label"
			v-bind="{ label, isChecked, disabled }"
		>
			<span
				v-if="label"
				:class="`font-text-${size || 'm'}`"
				style="color: inherit"
				>{{ label }}</span
			>
		</slot>
	</label>
</template>

<script>
import { defineComponent } from "vue";

import colors from "./definitions/colors";
import sizes from "./definitions/sizes";

export default defineComponent({
	name: "TnToggle",

	model: {
		prop: "selected",
		event: "change",
	},

	props: {
		/**
		 * Dark
		 * @type Boolean
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Sets the backgroundcolor of the switch when enabled
		 * @values blue, red, green, orange
		 */
		color: {
			type: String,
			default: "blue",
			validator: function (value) {
				return colors.indexOf(value.toLowerCase()) !== -1;
			},
		},
		/**
		 * Sets the size of the toggle
		 * @values s, m
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Disables toggling
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: [Array, Boolean],
			default: () => [],
		},
		name: {
			type: String,
			default: "",
		},
		modelValue: {
			type: Boolean,
			default: false,
		},
		/**
		 * Show loading animation
		 */
		loading: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
		},
	},

	computed: {
		isChecked() {
			return this.modelValue;
		},
		checkedClass() {
			return this.isChecked ? "on" : "off";
		},
		loadingColor() {
			if (this.dark) {
				return this.isChecked ? "color-cta-dark-default" : "color-neutrals-500-core";
			} else {
				return this.isChecked ? "color-cta-default" : "color-neutrals-500-core";
			}
		},
	},

	methods: {
		handleInput() {
			this.$emit("update:modelValue", !this.modelValue);
		},
	},
});
</script>

<style lang="scss">
@use "@/assets/scss/variables" as variables;

.tn-toggle {
	--background-color-track-ON: #{variables.$color-cta-default};
	--background-color-handle-ON: #{variables.$color-primary-superlight};
	--background-color-track-OFF: #{variables.$color-neutrals-500-core};
	--background-color-handle-OFF: #{variables.$color-neutrals-white};
	--hover-track-color-ON: #{variables.$color-cta-hover};
	--hover-handle-color-ON: #{variables.$color-primary-superlight};
	--hover-handle-color-OFF: #{variables.$color-neutrals-white};
	--hover-track-color-OFF: #{variables.$color-neutrals-600-shade};
	--active-track-color-ON: #{variables.$color-cta-active};
	--active-handle-color-ON: #{variables.$color-primary-superlight};
	--active-handle-color-OFF: #{variables.$color-neutrals-white};
	--active-track-color-OFF: #{variables.$color-neutrals-700-shade};
	--disabled-color-track: #{variables.$color-neutrals-300-tint};
	--disabled-color-handle: #{variables.$color-neutrals-white};
	--focus-outline-color: #{variables.$color-cta-focus};
	--text-color: #{variables.$color-neutrals-black};
	--text-disabled-color: #{variables.$color-neutrals-300-tint};

	position: relative;

	// display: inline-block;
	display: inline-flex;
	align-items: center;
	gap: 8px;
	outline: none;
	color: var(--text-color);

	&.dark {
		--background-color-track-ON: #{variables.$color-cta-dark-default};
		--background-color-handle-ON: #{variables.$color-neutrals-black};
		--background-color-track-OFF: #{variables.$color-neutrals-500-core};
		--background-color-handle-OFF: #{variables.$color-neutrals-black};
		--hover-track-color-ON: #{variables.$color-cta-dark-hover};
		--hover-handle-color-ON: #{variables.$color-neutrals-black};
		--hover-handle-color-OFF: #{variables.$color-neutrals-black};
		--hover-track-color-OFF: #{variables.$color-neutrals-400-tint};
		--active-track-color-ON: #{variables.$color-cta-dark-active};
		--active-handle-color-ON: #{variables.$color-neutrals-black};
		--active-handle-color-OFF: #{variables.$color-neutrals-black};
		--active-track-color-OFF: #{variables.$color-neutrals-300-tint};
		--disabled-color-track: #{variables.$color-neutrals-700-shade};
		--disabled-color-handle: #{variables.$color-neutrals-black};
		--focus-outline-color: #{variables.$color-cta-dark-focus};
		--text-color: #{variables.$color-neutrals-white};
		--text-disabled-color: #{variables.$color-neutrals-700-shade};
	}

	input:focus-visible + .track {
		transition: none;
		outline-offset: 3px;
		outline: 2px solid var(--focus-outline-color);
	}

	input {
		opacity: 0;
		width: 0;
		height: 0;
		display: hidden;
		position: absolute;
	}

	&.off {
		.track {
			background-color: var(--background-color-track-OFF);

			.handle {
				background-color: var(--background-color-handle-OFF);
			}
		}

		&:hover {
			.track {
				background-color: var(--hover-track-color-OFF);

				.handle {
					background-color: var(--hover-handle-color-OFF);
				}
			}
		}

		&:active {
			.track {
				background-color: var(--active-track-color-OFF);

				.handle {
					background-color: var(--active-handle-color-OFF);
				}
			}
		}
	}

	&.on {
		.track {
			background-color: var(--background-color-track-ON);

			.handle {
				transform: translateX(100%) translateY(-50%);
				background-color: var(--background-color-handle-ON);
			}
		}

		&:hover {
			.track {
				background-color: var(--hover-track-color-ON);

				.handle {
					background-color: var(--hover-handle-color-ON);
				}
			}
		}

		&:active {
			.track {
				background-color: var(--active-track-color-ON);

				.handle {
					background-color: var(--active-handle-color-ON);
				}
			}
		}
	}

	&.disabled,
	&.loading {
		color: var(--text-disabled-color);

		.track {
			background-color: var(--disabled-color-track) !important;
			cursor: not-allowed;

			.handle {
				background-color: var(--disabled-color-handle) !important;
			}
		}
	}

	.track {
		position: relative;
		inset: 0;
		border-radius: 1000px;
		cursor: pointer;
		transition: 0.3s ease;

		.handle {
			position: absolute;
			transition: 300ms cubic-bezier(0.8, 0, 0.2, 1);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 50%;
			transform: translateY(-50%);
			left: 2px;
		}
	}

	&.size {
		&-s {
			.track {
				width: 36px;
				height: 20px;

				.handle {
					width: 16px;
					height: 16px;

					.loader {
						width: 15px !important;
						height: 15px !important;
					}
				}
			}
		}

		&-m {
			.track {
				width: 44px;
				height: 24px;

				.handle {
					width: 20px;
					height: 20px;

					.loader {
						padding: 2px;
						width: 20px !important;
						height: 20px !important;
					}
				}
			}
		}

		&-l {
			.track {
				width: 44px;
				height: 24px;

				.handle {
					width: 20px;
					height: 20px;

					.loader {
						width: 16px !important;
						height: 16px !important;
					}
				}
			}
		}
	}
}
</style>
